<template>
  <div class="key-value" :class="{
    'key-value--hidden': hidden,
    'key-value--table': table,
    'key-value--child': child,
    'key-value--highlight': highlight,
    'key-value--multiple': multiple,
    'key-value--required': required,
    'key-value--success': success,
    'key-value--error': error,
  }">
    <div class="key-value-label" v-if="label && !labelInput">
      {{ label }}
    </div>
    <div class="key-value-label" v-else>
      <slot name="inputLabel"></slot>
    </div>
    <div class="key-value-value">
      <span v-if="type !== 'file' || type === 'input'">
        {{ getValue() }}
      </span>

      <div v-else :class="image ? `key-value-image` : null" :style="image ? `background-image: url('${image}');'` : null">
        {{ !image ? $props.default : null }}
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  name: 'KeyValue',

  props: {
    label: {
      type: String,
    },
    labelInput: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number, Array, Object, Boolean],
    },
    default: {
      type: [String, Number, Array, Object],
      default: 'Non renseigné',
    },
    type: {
      // simple (number, password, email, tel, url)
      // checkbox, radio, select, date, time, file
      // input, repeatable
      // TODO: color, range
      type: String,
      default: 'simple',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Boolean,
      default: false,
    },
    child: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      multiple: false,
    },
    required: {
      type: Boolean,
      multiple: false,
    },
    success: {
      type: Boolean,
      multiple: false,
    },
    error: {
      type: Boolean,
      multiple: false,
    },
  },

  // Is the slot empty or not?
  // See: https://stackoverflow.com/questions/47432702/determining-if-slot-content-is-null-or-empty/47434345
  setup(_, { slots }) {
    const hasSlotContent = computed(() => !!slots.default)

    return {
      hasSlotContent,
    }
  },

  data() {
    return {
      loading: false,
      image: null,
      text: null,
    }
  },

  mounted() {
    if (this.type === 'file' && this.value) {
      this.fileService.displayImage(this.value.jeton).then(
        (response) => {
          this.image = response.data
        },
      )
    }
  },

  methods: {
    getValue() {
      if ((this.type === 'simple' || this.type === 'radio') && this.value) {
        let value
        if (typeof this.value === 'boolean') {
          if (this.value) {
            value = 'Oui'
          } else {
            value = 'Non'
          }
        }
        if (value) {
          return value
        }

        return this.value
      }

      if (this.type === 'checkbox') {
        if (!this.value) {
          return this.checked ? 'OUI' : 'NON'
        }
        return this.value.join(', ')
      }

      if (this.type === 'select') {
        return Array.isArray(this.value) ? this.value.join(', ') : this.value
      }

      if (this.type === 'date') {
        if (this.value) {
          return new Date(this.value).toLocaleDateString('fr-FR', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        }
        return this.default
      }
      if (this.type === 'time') {
        return this.value
      }
      if (!this.hasSlotContent) {
        let value
        if (typeof this.value === 'boolean') {
          if (this.value) {
            value = 'Oui'
          } else {
            value = 'Non'
          }
        }
        if (value) {
          return value
        }
        return this.default
      }
      return null
    },
  },
}
</script>

<style lang="scss" scoped>
/* KEY VALUE */

.key-value {
  &:not(:last-child) {
    margin-bottom: $gutter;
  }
}

.key-value--hidden {
  display: none;
}

.key-value-label {
  margin-bottom: $gutter-quarter;
  font-size: $font-size-small;
  line-height: $line-height-small; // Compact for multiline
  font-weight: $font-weight-semibold;
}

.key-value-value {
  font-size: $font-size-big;
  font-weight: $font-weight-semibold;
  color: $color-gray;

  .key-value--break-word & {
    word-break: break-all;
  }
}

/* SUCCESS */

.key-value--success {
  .key-value-value {
    color: $color-primary;
  }
}

/* ERROR */

.key-value--error {
  .key-value-value {
    color: $color-error;
  }
}

/* REQUIRED */

.key-value--required {
  .key-value-label {
    &::after {
      display: inline-block;
      margin-left: $gutter-eighth / 2;
      content: "*";
      color: $color-error;
      transform: translateY(-$gutter-eighth);
    }
  }
}

/* IMAGE */

$image-size: 8rem;

.key-value-image {
  display: flex;
  justify-content: center;
  align-items: center;
  @include size($image-size);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

/* TABLE */

.key-value--table {

  &:last-child,
  &:not(:last-child) {
    margin-bottom: 0;
  }

  .key-value-label,
  .key-value-value {
    padding: $gutter-quarter;
    font-size: $font-size-base;
  }

  .key-value-label {
    margin-bottom: 0;
    background-color: $color-gray-lighter;
    color: $color-primary;
  }

  .key-value-value {
    font-weight: $font-weight-normal;
    margin-bottom: $gutter-half;
  }

  &.key-value--child {
    .key-value-label {
      background-color: $color-gray-lightestest;
      font-weight: $font-weight-normal;
    }
  }

  &.key-value--highlight {
    .key-value-label {
      background-color: $color-primary;
      color: white;
    }
  }

  &.key-value--multiple {
    .key-value-value {
      display: flex;

      ::v-deep(> *) {
        flex-basis: 33.33%;
      }

      ::v-deep(span) {
        display: none;
      }
    }
  }

  &.key-value--multiple.key-value--highlight {
    .key-value-value {
      font-weight: $font-weight-bold;
      color: $color-primary;
    }
  }

  @include bp('xs') {
    display: flex;

    .key-value-label,
    .key-value-value {
      padding: ($gutter-eighth * 3) $gutter-half;
    }

    .key-value-label {
      flex-basis: 20rem;

      @include bp('sm') {
        flex-basis: min(35%, 30rem);
      }

      flex-shrink: 0;
      background-color: $color-gray-lightest;
      border-top: 1px solid white;
    }

    .key-value-value {
      flex-grow: 1;
      margin-bottom: 0;
      border-top: 1px solid $color-gray-lighter;
    }

    &:last-child {
      .key-value-value {
        border-bottom: 1px solid $color-gray-lighter;
      }

      .key-value-label {
        border-top: 1px solid white;
      }
    }

    &.key-value--child {
      .key-value-label {
        text-align: right;
      }
    }
  }

  @include bp('md') {

    .key-value-label,
    .key-value-value {
      @include h-padding($gutter);
    }
  }

  // Tabular key-values in modal

  .modal & {
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: $color-gray-lighter;
  }

}
</style>
